<template>
  <div>
    <div class="container-fluid mt-4">
      <loading-overlay :active.sync="loading2" :is-full-page="true" />
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0 text-primary">Facturas almacenadas</h3>
              </div>
              <div class="col-6 text-right">
                <base-button
                  :disabled="loading1"
                  @click="exportData()"
                  type="info"
                  icon
                  size="sm"
                >
                  <span class="btn-inner--icon"
                    ><i class="fas fa-file-excel"></i
                  ></span>
                  <span class="btn-inner--text">Exportar todo</span>
                </base-button>
              </div>
            </div>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select p-2"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div class="vld-parent">
                <loading-overlay
                  :height="30"
                  :active.sync="loading1"
                  :is-full-page="false"
                />
                <el-select
                    v-model="countries.select"
                    filterable
                    placeholder="País:"
                    @change="changeCountry()"
                  >
                    <el-option
                      v-for="option in countries.multiple"
                      :key="option.value"
                      :label="option.label"
                      :value="option"
                    >
                    </el-option>
                  </el-select>
              </div>
              <div class="pt-2 d-flex flex-row">
                <base-input
                  v-model="query"
                  type="search"
                  prepend-icon="fas fa-search"
                  placeholder="Buscar..."
                  clearable
                  style="padding-right: 0.4vw;"
                />
                <base-button
                class="btn-sm" 
                type="info"
                id="searchbtn" 
                @click="getList()">
                Buscar
                </base-button>
              </div>
            </div>
            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="facturas"
              @sort-change="sortChange"
            >
              <div class="vld-parent" slot="empty" v-if="loading">
                <loading-overlay :active.sync="loading" :is-full-page="false" />
                <img
                  src="/img/white_image.png"
                  style="height: 100px; width: 100px"
                />
              </div>
              <div slot="empty" v-if="facturas.length == 0 && !loading">
                <span>No se ha encontrado</span>
              </div>
              <el-table-column
                min-width="200px"
                label="Usuario"
                prop="name"
                sortable="custom"
              />      
              <el-table-column
                min-width="150px"
                label="No. factura"
                prop="bill_number"
                sortable="custom"
              />
              <el-table-column
                min-width="210px"
                label="Fecha de la factura"
                prop="bill_date"
                sortable="custom"
              />
              <el-table-column
                min-width="150px"
                label="Promoción"
                prop="promotion"
                sortable="custom"
              />
              <el-table-column
                min-width="220px"
                label="Cadena"
                prop="new_chain"
                sortable="custom"
              />
              <el-table-column
                min-width="220px"
                label="Farmacia"
                prop="pharmacy_name"
                sortable="custom"
              />
              <el-table-column
                min-width="220px"
                label="Dirección"
                prop="pharmacy_address"
                sortable="custom"
              />
              <el-table-column
                min-width="250px"
                align="center"
                label="Archivo"
              >
                <div slot-scope="{ row }" class="table-actions">
                  <a
                    type="text"
                    :href="row.link"
                    target="_blank"
                    class="table-action"
                    style="cursor: pointer"
                  >
                   {{row.link}}
                  </a>
                </div>
              </el-table-column>
              <el-table-column
                align="center"
                min-width="150px"
                label="Acciones"
              >
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip content="Borrar" placement="top">
                    <a
                      @click="deleteBill(row)"
                      class="table-action table-action-delete"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="fas fa-trash-alt" />
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Mostrando {{ total ? from + 1 : 0 }} a {{ to }} de
                {{ total }} entradas
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            />
          </div>
        </card>
        <modal :show.sync="modal">
          <h6 slot="header" class="modal-title">Exportar datos</h6>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <base-input label="Fecha inicial" alternative class="mb-3">
                <flat-picker
                  slot-scope="{ focus, blur }"
                  @on-open="focus"
                  @on-close="blur"
                  :config="config"
                  class="form-control datepicker"
                  v-model="inicio"
                >
                </flat-picker>
              </base-input>
            </div>
            <div class="col-md-6 col-sm-12">
              <base-input label="Fecha final" alternative class="mb-3">
                <flat-picker
                  slot-scope="{ focus, blur }"
                  @on-open="focus"
                  @on-close="blur"
                  :config="config"
                  class="form-control datepicker"
                  v-model="final"
                  enable="false"
                >
                </flat-picker>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <base-input label="País" alternative class="mb-3">
                <el-select
                    class="select-primary pagination-select"
                    v-model="countries_export.select"
                    filterable
                  >
                    <el-option
                      v-for="option in countries_export.multiple"
                      :key="option.value"
                      :label="option.label"
                      :value="option"
                    >
                    </el-option>
                  </el-select>
              </base-input>
            </div>
            <div class="col-md-6 col-sm-12 text-right">
              <base-button
                :disabled="validate"
                @click="exportData()"
                style="top: 50%"
                type="primary"
                size="sm"
                >Exportar</base-button
              >
            </div>
            <div></div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Modal } from "@/components";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es";
import XLSX from "xlsx-color";

import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    /* RouteBreadCrumb, */
    Modal,
    flatPicker,
  },

  data() {
    return {
      config: {
        altFormat: "d M, Y",
        altInput: true,
        allowInput: true,
        dateFormat: "m-d-Y",
        locale: Spanish,
      },
      facturas: [],
      countries: {select: '', multiple: []},
      countries_export: {select: '', multiple: []},
      country_export: null,
      query: null,

      sort: "-bill_date",

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
      loading: false,
      able: true,
      loading1: false,
      modal: false,
      inicio: new Date(),
      final: new Date(),
      loading2: false,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    validate: function () {
      return !this.countries_export.select || !this.inicio || !this.final;
    },
  },
  async created() {
    await this.getCountries();
  },
  watch: {
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },
  methods: {
    openModal() {
      this.modal = true;
      this.inicio = new Date();
      this.final = new Date();
    },
    changeCountry() {
      this.getList();
    },
    deleteBill(row) {
      swal.fire({
        title: `¡Precaución!`,
        text: `Estás a punto de borrar la factura ${row.bill_number} de ${row.name}.`,
        showCancelButton: true,
        confirmButtonText: `Borrar`,
        cancelButtonText: `Cancelar`,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        icon: "warning",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          return await this.$store.dispatch("bills/deleteBill", row).then(() => {
            let response = this.$store.getters["bills/deletedBill"];

            if (!response.success) { throw new Error(response.message); }

            return response;
          }).catch(error => {
            swal.showValidationMessage(`Ocurrió un error: ${error}`);
          });
        },
        allowOutsideClick: () => !swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          swal.fire({
            title: `¡Éxito!`, 
            text: `Factura ${row.bill_number} eliminada.`,
            confirmButtonText: `Continuar`,
            icon: "success",
          }).then((result) => {
            this.$router.go();
          });
        }
      });
    },
    async getCountries() {
      let aux = {};

      if (this.countries.select !== '') {
        aux = this.countries.select; 
      }

      this.countries = {select: '', multiple: []};
      await this.$store.dispatch("countries/list").then(() => {
        const self = this;
        let HelperContries = this.$store.getters["countries/list"]

        for(let i = 0; i < HelperContries.length; i++){
          let Nuevo = {"value": HelperContries[i].country_id, "label": HelperContries[i].name}
          self.countries.multiple.push(Nuevo)
          self.countries_export.multiple.push(Nuevo)
        }

        if (this.query === null) {
          this.countries.select = {value: 1, label: "Guatemala"};
        } else {
          this.countries.select = aux;
          //this.countries.select = {value: this.query.country_id, label: this.query.country_name};
        }

        this.countries_export.multiple.push({"value":-1,"label":"Todos"});
        this.countries_export.select = {value:-1,label:"Todos"};
        this.loading1 = false;
        this.getList();
      });
    },
    async getList() {
      if (this.able) {
        this.loading = true;
        this.facturas = [];
        try {
          this.able = false;
          const params = {
            pais: this.countries.select.value,
            limit: this.pagination.perPage,
            page: this.pagination.currentPage,
            order: this.sort,
            search: this.query,
          };
          await this.$store.dispatch("bills/getAllBills", params);
          const response = this.$store.getters["bills/all_bills"];
          for(let i = 0; i < response.data.length; i++){
            response.data[i].product_name = 'Club Facturas';
            response.data[i].pharmacy = 'FARMACIA SAN JUAN VILLA SOL';
            response.data[i].file = null
          }
          this.facturas = response.data;
          this.total = response.meta.page.total;
          //this.getCountries();
          this.loading = false;
          this.able = true;
        } catch (e) {
          this.loading = false;
          this.able = true;
          this.$notify({
            type: "danger",
            message: `Lo sentimos, inténtelo más tarde.`,
          });
        }
      }
    },
    getListDebounced: _.debounce(function () {
      this.getCountries();
    }, 300),
    formatDate(date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
    async exportData() {
      this.loading2 = true;

     
      await this.$store.dispatch("bills/exportAllBills", this.countries.select.value);
      const response = this.$store.getters["bills/export_all_bills"];
      
      if (response.length > 0) {
        let data = XLSX.utils.json_to_sheet(response, {
          header: [
            "Pais",
            "NombreCompleto",
            "NumFactura",
            "CreacionFactura",
            "FechaFactura",
            "NombrePromocion",
            "newchain",
            "Farmacia",
            "Direccion",
            "Archivo"
          ],
        });
        const style = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "7ea6f6" }
          },
          font: {
            name: 'Song Ti',
            /* sz: 12, */
            bold: true,
            color: { rgb: "FFFFFF" }
          },
          alignment: { 
            horizontal: "left", vertical: "center", wrap_text: true 
          } 
        };

        data["A1"].v = "País";
        data["A1"].s=style;
        data["B1"].v = "Usuario";
        data["B1"].s=style;
        data["C1"].v = "Número de factura";
        data["C1"].s=style;
        data["D1"].v = "Fecha de carga";
        data["D1"].s=style;
        data["E1"].v = "Fecha de la factura";
        data["E1"].s=style;
        data["F1"].v = "Promoción";
        data["F1"].s=style;
        data["G1"].v = "Cadena";
        data["G1"].s=style;
        data["H1"].v = "Farmacia";
        data["H1"].s=style;
        data["I1"].v = "Dirección";
        data["I1"].s=style;
        data["J1"].v = "Archivo";
        data["J1"].s=style;
        
        var wscols = [];
        var temp = {a: 0, b: 0, c: 0, d: 0, e: 0, f: 0, g: 0, h: 0, i: 0, j: 0};
     
        for (let value in data) {
          let width = data[value].v == null ? 0 : String(data[value].v).length;
          if (value[0] == "A") {width > temp.a ? temp.a = width + 2 : width; continue };
          if (value[0] == "B") {width > temp.b ? temp.b = width + 2 : width; continue };
          if (value[0] == "C") {width > temp.c ? temp.c = width + 2 : width; continue };
          if (value[0] == "D") {width > temp.d ? temp.d = width + 2 : width; continue };
          if (value[0] == "E") {width > temp.e ? temp.e = width + 2 : width; continue };
          if (value[0] == "F") {width > temp.f ? temp.f = width + 2 : width;  continue };
          if (value[0] == "G") {width > temp.g ? temp.g = width + 2 : width; continue };
          if (value[0] == "H") {width > temp.h ? temp.h = width + 2 : width; continue };
          if (value[0] == "I") {width > temp.i ? temp.i = width + 2 : width; continue };
          if (value[0] == "J") {width > temp.j ? temp.j = width + 2 : width; continue };
        }  
        var wscols = [
          { wch: temp.a }, // "characters"
          { wch: temp.b },
          { wch: temp.c },
          { wch: temp.d },
          { wch: temp.e },
          { wch: temp.f },
          { wch: temp.g },
          { wch: temp.h },
          { wch: temp.i },
          { wch: temp.j },
        ];
       
        data["!cols"] = wscols; 
 
        const workbook = XLSX.utils.book_new();
       
        const filename = `Reporte_De_facturas`;
      
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      } else {
        swal.fire({
          title: `¡Atención!`,
          text: "No existen facturas para este país y/o rango de fechas.",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          icon: "warning",
        });
      }
      this.loading2 = false;
      this.modal = false;
    },
  },
};
</script>
